@import '../styles/variables';
@import '../styles/fonts/lato.scss';
@import '../styles/fonts/source-sans-pro.scss';
@import '../styles/fonts/source-serif-pro.scss';

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: $color-blue;
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote {
  font-family: 'Source Serif Pro', serif;
  font-weight: 700;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: #000
  }
}
