@import './styles/bootstrap.scss';
@import './styles/typography.scss';

html {
  scroll-behavior: smooth;
}

body {
  &.no-scroll {
    overflow: hidden;
  }
}

hauk-header {
  & ol,
  & ul {
    padding-left: 1.2rem;
  }
}

.hauk-header-img-26-rem {
  @include media-breakpoint-up(md) {
    max-width: 26rem !important;
  }
}

.hauk-header-img-30-rem {
  @include media-breakpoint-up(md) {
    max-width: 30rem !important;
  }
}

.hauk-quote-personal-intern {
  width: 10rem;
}
