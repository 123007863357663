@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../../assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf) format('truetype');
}
