@import './variables.scss';

$theme-colors: (
  "blue": $color-blue,
  "light-blue": $color-light-blue
);

/**
 * Only import the necessary parts of Bootstrap
 * to reduce the total build size.
 */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~bootstrap/scss/root';
@import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/type";
// @import '~bootstrap/scss/images';
// @import '~bootstrap/scss/code';
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
// @import '~bootstrap/scss/tables';
// @import '~bootstrap/scss/forms';
// @import '~bootstrap/scss/buttons';
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/forms";
// @import '~bootstrap/scss/dropdown';
// @import '~bootstrap/scss/button-group';
// @import '~bootstrap/scss/input-group';
// @import '~bootstrap/scss/custom-forms';
// @import '~bootstrap/scss/nav';
// @import '~bootstrap/scss/navbar';
// @import '~bootstrap/scss/card';
// @import '~bootstrap/scss/breadcrumb';
// @import '~bootstrap/scss/pagination';
// @import '~bootstrap/scss/badge';
// @import '~bootstrap/scss/jumbotron';
// @import '~bootstrap/scss/alert';
// @import '~bootstrap/scss/progress';
// @import '~bootstrap/scss/media';
// @import '~bootstrap/scss/list-group';
// @import '~bootstrap/scss/close';
// @import '~bootstrap/scss/toasts';
// @import '~bootstrap/scss/modal';
// @import '~bootstrap/scss/tooltip';
// @import '~bootstrap/scss/popover';
// @import '~bootstrap/scss/carousel';
// @import '~bootstrap/scss/spinners';
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";
// @import '~bootstrap/scss/print';
